import styled from "styled-components";

export const Wrapper = styled.ul`
  display: none;
  margin: 40px 0 0;
  font-size: 32px;
  line-height: 1.33;
  text-transform: uppercase;

  @keyframes showLine {
    0% {
      left: -20px;
      width: 0;
    }
    40% {
      left: -20px;
      width: 0;
    }
    45% {
      left: -20px;
      width: calc(100% + 40px);
    }
    55% {
      left: auto;
      right: -20px;
      width: calc(100% + 40px);
    }
    60% {
      right: -20px;
      width: 0;
    }
    75% {
      left: -20px;
      width: 0;
    }
    100% {
      left: -20px;
      width: 0;
    }
  }

  > li span {
    position: relative;
    display: inline-block;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: -20px;
      margin-top: -3px;
      width: 0;
      height: 1px;
      background: black;
      animation-name: showLine;
      animation-duration: 10s;
      animation-delay: 5s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }

  > li:nth-child(1) span::after {
    animation-delay: 1s;
  }

  > li:nth-child(2) span::after {
    animation-delay: 3s;
  }

  > li:nth-child(3) span::after {
    animation-delay: 5s;
  }

  ${({ theme }) => theme.media.desktop} {
    display: block;
  }

  ${({ theme }) => theme.media.tv} {
    margin: 50px 0 0;
  }
`;
