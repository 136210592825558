import React, { useState, useEffect, useLayoutEffect, forwardRef } from "react";
import PropTypes from "prop-types";

import Button from "@atoms/Button";

import {
  Wrapper,
  Image,
  ImageInner,
  Text,
  Line,
  Close,
  StyledButton,
  Projects,
  Project,
} from "./styles.js";

import { people } from "@content/people";
import { peopleImages } from "@content/people/peopleImages";
import { projectsImages } from "@content/projects/projectsImages";

const Popup = ({ visible, buttonIndex, onClose }, ref) => {
  const [personToShow, setPersonToShow] = useState(false);
  const [imageActive, setImageActive] = useState(false);
  const [projectActive, setProjectActive] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    setPersonToShow(getPerson(buttonIndex));
    setTimeout(() => !visible && setProjectActive(false), 200);
  }, [buttonIndex, visible, setPersonToShow]);

  useLayoutEffect(() => {
    setTimeout(() => visible && setImageActive(true), 600);
  });

  const handleClose = () => {
    onClose && onClose();
    setIsClosing(true);
    setTimeout(() => setImageActive(false), 200);
    setTimeout(() => setIsClosing(false), 1000);
  };

  // const handleProjectClick = (id) => {
  //   id === projectActive ? setProjectActive(false) : setProjectActive(id);
  // };

  const getPerson = (id) => {
    const chosenPerson = people.filter((person) => {
      return person.id === id;
    });

    return chosenPerson[0];
  };

  const getPersonImage = (id) => {
    const chosenPerson = peopleImages.filter((person) => {
      return person.id === id;
    });

    return chosenPerson[0].image;
  };

  const getProjectThumbnail = (id) => {
    const chosenProject = projectsImages.filter((project) => {
      return project.id === id;
    });

    return chosenProject[0].thumbnail;
  };

  const getProjectPreview = (id) => {
    const chosenProject = projectsImages.filter((project) => {
      return project.id === id;
    });

    return chosenProject[0].preview;
  };

  return (
    <Wrapper ref={ref} visible={visible}>
      <Image
        buttonIndex={buttonIndex}
        visible={imageActive}
        isClosing={isClosing}
      >
        <ImageInner visible={visible} isClosing={isClosing}>
          <img
            src={personToShow ? getPersonImage(personToShow.id) : ""}
            alt=""
          />
          {/* <ProjectImage
            visible={projectActive}
            image={projectActive && getProjectPreview(projectActive)}
          /> */}
        </ImageInner>
      </Image>
      <Text visible={visible}>
        <span>
          ({personToShow && personToShow.city},{" "}
          {personToShow && personToShow.country})
        </span>
        <h3>{personToShow && personToShow.name}</h3>
        <h4>Bio</h4>
        <p>{personToShow && personToShow.bio}</p>
        {personToShow && personToShow.clients !== "" && (
          <>
            <Line visible={visible} />
            <h4>Clients</h4>
            <p>{personToShow && personToShow.clients}</p>
          </>
        )}
      </Text>
      <StyledButton visible={visible}>
        <a href={`mailto:${personToShow && personToShow.mail}`}>
          <Button color="red">Book my time</Button>
        </a>
      </StyledButton>
      <Projects visible={visible}>
        {personToShow &&
          personToShow.projects.map(
            (project, index) =>
              index < 12 && (
                <Project
                  image={getProjectThumbnail(project.id)}
                  active={project.id === projectActive}
                  key={project.id}
                  visible={visible}
                  href={getProjectPreview(project.id)}
                />
              )
          )}
      </Projects>
      <Close visible={visible} onClick={() => handleClose()}>
        X
      </Close>
    </Wrapper>
  );
};

Popup.propTypes = {
  visible: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

Popup.defaultProps = {
  visible: false,
  buttonIndex: 1,
};

export default forwardRef(Popup);
