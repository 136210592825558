import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  border-bottom: 1px solid #ede4dd;
  padding: 30px 40px;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;

  ${({ theme }) => theme.media.tablet} {
    padding: 28px 45px;
  }

  ${({ theme }) => theme.media.desktop} {
    padding: 33px 55px;
  }

  ${({ theme }) => theme.media.tv} {
    padding: 38px 70px;
  }

  &:nth-last-of-type(3),
  &:nth-last-of-type(2),
  &:nth-last-of-type(1) {
    border-bottom: none;
  }

  &:nth-of-type(2),
  &:nth-of-type(5),
  &:nth-of-type(8),
  &:nth-of-type(11),
  &:nth-of-type(14) {
    &::before,
    &::after {
      position: absolute;
      top: 50px;
      bottom: 50px;
      left: 0;
      width: 1px;
      background: black;
      content: "";
    }

    &::after {
      right: 0;
      left: auto;
    }
  }

  > span {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 0;
    height: 60%;
    border-radius: 80px;
    background: black;
    transition: transform 0.05s ease, width 0.3s ease;
  }

  > span:nth-child(1) {
    bottom: -5%;
    right: -15%;
    transform-origin: 50% 0%;
    transform: rotate(2deg);
    // transition-delay: 1.25s;
  }

  > span:nth-child(2) {
    right: auto;
    bottom: 25%;
    left: -15%;
    transform-origin: 50% 100%;
    transform: rotate(-3deg);
    // transition-delay: 1.125s;
  }

  > span:nth-child(3) {
    bottom: 55%;
    right: -20%;
    transform-origin: 50% 0%;
    transform: rotate(3deg);
    // transition-delay: 1s;
  }

  > div {
    position: relative;
    z-index: 1;
  }

  ${({ active }) =>
    active &&
    `
      > span:nth-child(1) {
        width: 150%;
        transition-delay: 0s;
        color: white !important;
      }

      > span:nth-child(2) {
        width: 150%;
        transition-delay: 0.125s;
        color: white !important;
      }

      > span:nth-child(3) {
        width: 150%;
        transition-delay: 0.25s;
        color: white !important;
      }

      p {
        color: white !important;

        > span > span {
          // color: white !important;

          &::before,
          &::after {
            color: white !important;
          }
        }
      }
    `}

  ${({ clicked }) =>
    clicked &&
    `
    > span:nth-child(1) {
      transition-delay: 1.25s;
    }

    > span:nth-child(2) {
      transition-delay: 1.125s;
    }

    > span:nth-child(3) {
      transition-delay: 1s;
    }

    p {
      color: white !important;
      > span > span {
        // color: white !important;
        &::before,
        &::after {
          color: white !important;
        }
      }
    }
  `}
`;

export const SubLabel = styled.p`
  position: relative;
  display: block;
  font-family: "gotham", sans-serif;
  font-size: 12px;

  ${({ theme }) => theme.media.tablet} {
    font-size: 12px;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 14px;
  }

  ${({ theme }) => theme.media.bigDesktop} {
    font-size: 18px;
  }

  ${({ theme }) => theme.media.tv} {
    font-size: 20px;
  }
`;

export const Label = styled.p`
  position: relative;
  display: block;
  font-size: 18px;
  white-space: nowrap;

  ${({ theme }) => theme.media.tablet} {
    font-size: 24px;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 30px;
  }

  ${({ theme }) => theme.media.bigDesktop} {
    font-size: 34px;
  }

  ${({ theme }) => theme.media.tv} {
    font-size: 44px;
  }
`;
