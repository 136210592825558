import React from "react";
import PropTypes from "prop-types";

import { Wrapper, Label } from "./styles.js";

const ButtonCircle = ({ active, children }) => {
  return (
    <Wrapper active={active}>
      <Label>{children}</Label>
    </Wrapper>
  );
};

ButtonCircle.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

ButtonCircle.defaultProps = {
  active: false,
  children: null,
};

export default ButtonCircle;
