import styled from "styled-components";

export const Wrapper = styled.span`
  > span {
    position: relative;
    line-height: 1;
    color: transparent;
  }

  > span:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    color: black;
    line-height: 1;
    content: attr(data-letter);
  }

  > span:after {
    color: black;
    line-height: 1;
  }

  > span:before,
  > span:after {
    transition: transform 0.15s ease, opacity 0.15s ease;
  }

  > span:nth-child(8):after,
  > span:nth-child(6):after,
  > span:nth-child(3):after,
  > span:nth-child(2):after,
  > span:nth-child(1):after {
    position: absolute;
    top: 100%;
    left: 0;
    height: 100%;
    transform: translate3d(0, 0, 0);
    opacity: 0;
    content: attr(data-letter);
  }

  > span:nth-child(8):before,
  > span:nth-child(8):after {
    transition-delay: 0.25s;
  }

  > span:nth-child(6):before,
  > span:nth-child(6):after {
    transition-delay: 0.25s;
  }

  > span:nth-child(3):before,
  > span:nth-child(3):after {
    transition-delay: 0.35s;
  }

  > span:nth-child(2):before,
  > span:nth-child(2):after {
    transition-delay: 0.5s;
  }

  > span:nth-child(1):before,
  > span:nth-child(1):after {
    transition-delay: 0.65s;
  }

  ${({ active }) =>
    active &&
    `
    > span:nth-child(8):before,
    > span:nth-child(6):before,
    > span:nth-child(3):before,
    > span:nth-child(2):before,
    > span:nth-child(1):before {
      transform: translate3d(0, -100%, 0);
      opacity: 0;
    }

    > span:nth-child(8):after,
    > span:nth-child(6):after,
    > span:nth-child(3):after,
    > span:nth-child(2):after,
    > span:nth-child(1):after {
      transform: translate3d(0, -100%, 0);
      opacity: 1;
    }
    `}

  ${({ hoverAnimation }) =>
    hoverAnimation &&
    `
      &:hover {
        > span:nth-child(8):before,
        > span:nth-child(6):before,
        > span:nth-child(3):before,
        > span:nth-child(2):before,
        > span:nth-child(1):before {
          transform: translate3d(0, -100%, 0);
          opacity: 0;
        }

        > span:nth-child(8):after,
        > span:nth-child(6):after,
        > span:nth-child(3):after,
        > span:nth-child(2):after,
        > span:nth-child(1):after {
          transform: translate3d(0, -100%, 0);
          opacity: 1;
        }
      }`}
`;

export const Letter = styled.span`
  color: red;
`;
