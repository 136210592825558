import styled from "styled-components";

export const Wrapper = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  overflow: hidden;
  border: 1px solid black;
  border-radius: 50%;
  padding: 20px;
  color: ${({ theme }) => theme.colors.black} !important;
  text-transform: uppercase;
  cursor: pointer;

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -12px 0 0 -12px;
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 12px;
    transition: transform 0.6s ease;
    tranform-origin: center center;
  }

  ${({ active }) =>
    active &&
    `
      p {
        transform: rotate(360deg);
      }
    `}
`;

export const Label = styled.p`
  position: relative;
  display: inline-block;
  color: black;
  transition: color 0.15s ease;
  transition-delay: 0.1s;
`;
