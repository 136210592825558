import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 120px 0 140px;
  text-align: center;
  transition: transform 0.7s ease, opacity 0.7s ease;
  transition-delay: 1s;

  > div:nth-child(1) {
    width: 100%;
    padding: 140px 0 60px;

    > p {
      margin: 0 0 18px;
      font-family: "gotham", sans-serif;
      font-size: 18px;
      line-height: 2;
    }
  }

  > div:nth-child(2) {
    width: 100%;
    padding: 40px 30px;
    background: ${({ theme }) => theme.colors.white};
  }

  ${({ visible }) =>
    visible
      ? `transform: translate3d(0, 0, 0); opacity:1;`
      : `transform: translate3d(0, 150px, 0); opacity:0;`}

  ${({ theme }) => theme.media.tablet} {
    padding: 50px 0;

    > div:nth-child(1) > p {
      margin: 0 0 20px;
      font-size: 20px;
    }
  }

  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
    text-align: left;

    > div:nth-child(1) > p {
      margin: 0 0 22px;
      font-size: 22px;
    }

    > div:nth-child(1) {
      width: 50%;
      padding: 90px 60px 60px 0;
    }

    > div:nth-child(2) {
      width: 45%;
      max-width: 900px;
      padding: 70px 60px 90px;
    }
  }

  ${({ theme }) => theme.media.tv} {
    flex-direction: row;

    > div:nth-child(1) > p {
      margin: 0 0 24px;
      font-size: 24px;
    }

    > div:nth-child(1) {
      width: 45;
      padding: 90px 120px 60px 0;
    }

    > div:nth-child(2) {
      right: -100px;
      width: 48%;
      padding: 90px 100px 60px;
    }
  }
`;

export const Mission = styled.div`
  h3 {
    margin: 0 0 24px;
  }

  h4 {
    margin: 96px 0 36px;
    font-family: "gotham bold", sans-serif;
    font-size: 24px;
    line-height: 1;
    text-transform: uppercase;
  }
`;

export const Details = styled.div`
  font-family: "gotham", sans-serif;

  h4 {
    margin: 96px 0 36px;
    font-family: "gotham bold", sans-serif;
    font-size: 24px;
    line-height: 1;
    text-transform: uppercase;
  }

  p {
    margin: 0 0 14px;
    font-size: 16px;
  }

  a {
    display: inline-block;
    margin-right: 5px;

    &:after {
      display: inline-block;
      margin-left: 5px;
      content: "/";
    }

    &:last-child:after {
      display: none;
    }
  }

  ${({ theme }) => theme.media.tablet} {
    h4 {
      font-size: 26px;
    }

    p {
      margin: 0 0 14px;
      font-size: 18px;
    }
  }

  ${({ theme }) => theme.media.desktop} {
    h4 {
      font-size: 28px;
    }

    p {
      margin: 0 0 16px;
      font-size: 20px;
    }
  }

  ${({ theme }) => theme.media.tv} {
    h4 {
      font-size: 32px;
    }

    p {
      margin: 0 0 16px;
      font-size: 22px;
    }
  }
`;

export const CloudOne = styled.div`
  position: absolute;
  top: 150px;
  left: 300px;
  width: 245px;
  ${({ active }) => active && `animation: moveToRightOne 8s ease;`}
  animation-fill-mode: forwards;

  @keyframes moveToRightOne {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(250px);
    }
  }

  ${({ theme }) => theme.media.tablet} {
    top: 150px;
    left: 300px;
    width: 245px;

    @keyframes moveToRightOne {
      0% {
        transform: translateX(0px);
      }
      100% {
        transform: translateX(100px);
      }
    }
  }

  ${({ theme }) => theme.media.desktop} {
    top: 80px;
    left: 380px;
    width: 245px;

    @keyframes moveToRightOne {
      0% {
        transform: translateX(0px);
      }
      100% {
        transform: translateX(100px);
      }
    }
  }

  ${({ theme }) => theme.media.bigDesktop} {
    top: 80px;
    left: 430px;
    width: 245px;

    @keyframes moveToRightOne {
      0% {
        transform: translateX(0px);
      }
      100% {
        transform: translateX(150px);
      }
    }
  }

  ${({ theme }) => theme.media.tv} {
    top: 120px;
    left: 500px;
    width: 245px;

    @keyframes moveToRightOne {
      0% {
        transform: translateX(0px);
      }
      100% {
        transform: translateX(250px);
      }
    }
  }
`;

export const CloudTwo = styled.div`
  position: absolute;
  top: 30px;
  left: 20px;
  width: 160px;
  ${({ active }) => active && `animation: moveToRightTwo 12s ease;`}
  animation-fill-mode: forwards;
  pointer-events: none;

  @keyframes moveToRightTwo {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(400px);
    }
  }

  ${({ theme }) => theme.media.tablet} {
    top: 40px;
    left: 30px;
    width: 160px;
  }

  ${({ theme }) => theme.media.desktop} {
    top: 40px;
    left: 0px;
    width: 160px;

    @keyframes moveToRightTwo {
      0% {
        transform: translateX(0px);
      }
      100% {
        transform: translateX(400px);
      }
    }
  }

  ${({ theme }) => theme.media.tv} {
    top: 40px;
    left: 100px;
    width: 160px;

    @keyframes moveToRightTwo {
      0% {
        transform: translateX(0px);
      }
      100% {
        transform: translateX(400px);
      }
    }
  }
`;
