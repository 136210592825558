import agatawork1 from "@content/projects/agatawork1.jpg";
import agatawork2 from "@content/projects/agatawork2.jpg";
import agatawork3 from "@content/projects/agatawork3.jpg";
import agatawork4 from "@content/projects/agatawork4.jpg";
import agatawork5 from "@content/projects/agatawork5.jpg";
import agatawork6 from "@content/projects/agatawork6.jpg";
import agatawork7 from "@content/projects/agatawork7.jpg";
import agatawork8 from "@content/projects/agatawork8.jpg";
import alexwork1 from "@content/projects/alexwork1.jpg";
import alexwork2 from "@content/projects/alexwork2.jpg";
import alexwork3 from "@content/projects/alexwork3.jpg";
import alexwork4 from "@content/projects/alexwork4.jpg";
import alexwork5 from "@content/projects/alexwork5.jpg";
import alexwork6 from "@content/projects/alexwork6.jpg";
import alexwork7 from "@content/projects/alexwork7.jpg";
import alexwork8 from "@content/projects/alexwork8.jpg";
import barbarawork1 from "@content/projects/barbarawork1.jpg";
import barbarawork2 from "@content/projects/barbarawork2.jpg";
import barbarawork3 from "@content/projects/barbarawork3.jpg";
import barbarawork4 from "@content/projects/barbarawork4.jpg";
import barbarawork5 from "@content/projects/barbarawork5.jpg";
import barbarawork6 from "@content/projects/barbarawork6.jpg";
import barbarawork7 from "@content/projects/barbarawork7.jpg";
import barbarawork8 from "@content/projects/barbarawork8.jpg";
import christianwork1 from "@content/projects/christianwork1.jpg";
import christianwork2 from "@content/projects/christianwork2.jpg";
import christianwork3 from "@content/projects/christianwork3.jpg";
import christianwork4 from "@content/projects/christianwork4.jpg";
import christianwork5 from "@content/projects/christianwork5.jpg";
import christianwork6 from "@content/projects/christianwork6.jpg";
import christianwork7 from "@content/projects/christianwork7.jpg";
import christianwork8 from "@content/projects/christianwork8.jpg";
import dominikawork1 from "@content/projects/dominikawork1.jpg";
import dominikawork2 from "@content/projects/dominikawork2.jpg";
import dominikawork3 from "@content/projects/dominikawork3.jpg";
import dominikawork4 from "@content/projects/dominikawork4.jpg";
import dominikawork5 from "@content/projects/dominikawork5.jpg";
import dominikawork6 from "@content/projects/dominikawork6.jpg";
import dominikawork7 from "@content/projects/dominikawork7.jpg";
import dominikawork8 from "@content/projects/dominikawork8.jpg";
import gavilanwork1 from "@content/projects/gavilanwork1.jpg";
import gavilanwork2 from "@content/projects/gavilanwork2.jpg";
import gavilanwork3 from "@content/projects/gavilanwork3.jpg";
import gavilanwork4 from "@content/projects/gavilanwork4.jpg";
import gavilanwork5 from "@content/projects/gavilanwork5.jpg";
import gavilanwork6 from "@content/projects/gavilanwork6.jpg";
import gavilanwork7 from "@content/projects/gavilanwork7.png";
import gavilanwork8 from "@content/projects/gavilanwork8.png";
import lucaswork1 from "@content/projects/lucaswork1.jpg";
import lucaswork2 from "@content/projects/lucaswork2.jpg";
import lucaswork3 from "@content/projects/lucaswork3.jpg";
import lucaswork4 from "@content/projects/lucaswork4.jpg";
import lucaswork5 from "@content/projects/lucaswork5.jpg";
import lucaswork6 from "@content/projects/lucaswork6.jpg";
import lucaswork7 from "@content/projects/lucaswork7.jpg";
import lucaswork8 from "@content/projects/lucaswork8.jpg";
import matiaswork1 from "@content/projects/matiaswork1.jpg";
import matiaswork2 from "@content/projects/matiaswork2.jpg";
import matiaswork3 from "@content/projects/matiaswork3.jpg";
import matiaswork4 from "@content/projects/matiaswork4.jpg";
import matiaswork5 from "@content/projects/matiaswork5.jpg";
import matiaswork6 from "@content/projects/matiaswork6.jpg";
import matiaswork7 from "@content/projects/matiaswork7.jpg";
import matiaswork8 from "@content/projects/matiaswork8.jpg";
import monikawork1 from "@content/projects/monikawork1.jpg";
import monikawork2 from "@content/projects/monikawork2.jpg";
import monikawork3 from "@content/projects/monikawork3.jpg";
import monikawork4 from "@content/projects/monikawork4.jpg";
import monikawork5 from "@content/projects/monikawork5.jpg";
import monikawork6 from "@content/projects/monikawork6.jpg";
import monikawork7 from "@content/projects/monikawork7.jpg";
import monikawork8 from "@content/projects/monikawork8.jpg";
import patriciawork1 from "@content/projects/patriciawork1.jpg";
import patriciawork2 from "@content/projects/patriciawork2.jpg";
import patriciawork3 from "@content/projects/patriciawork3.jpg";
import patriciawork4 from "@content/projects/patriciawork4.jpg";
import patriciawork5 from "@content/projects/patriciawork5.jpg";
import patriciawork6 from "@content/projects/patriciawork6.jpg";
import patriciawork7 from "@content/projects/patriciawork7.jpg";
import patriciawork8 from "@content/projects/patriciawork8.jpg";
import sylwiawork1 from "@content/projects/sylwiawork1.jpg";
import sylwiawork2 from "@content/projects/sylwiawork2.jpg";
import sylwiawork3 from "@content/projects/sylwiawork3.jpg";
import sylwiawork4 from "@content/projects/sylwiawork4.jpg";
import sylwiawork5 from "@content/projects/sylwiawork5.jpg";
import sylwiawork6 from "@content/projects/sylwiawork6.jpg";
import sylwiawork7 from "@content/projects/sylwiawork7.jpg";
import tatianawork1 from "@content/projects/tatianawork1.jpeg";
import tatianawork2 from "@content/projects/tatianawork2.jpeg";
import tatianawork3 from "@content/projects/tatianawork3.jpeg";
import tatianawork4 from "@content/projects/tatianawork4.jpeg";
import tatianawork5 from "@content/projects/tatianawork5.jpeg";
import tatianawork6 from "@content/projects/tatianawork6.jpeg";
import tatianawork7 from "@content/projects/tatianawork7.jpeg";
import tatianawork8 from "@content/projects/tatianawork8.jpeg";
import weronikawork1 from "@content/projects/weronikawork1.jpg";
import weronikawork2 from "@content/projects/weronikawork2.jpg";
import weronikawork3 from "@content/projects/weronikawork3.jpg";
import weronikawork4 from "@content/projects/weronikawork4.jpg";
import weronikawork5 from "@content/projects/weronikawork5.jpg";
import weronikawork6 from "@content/projects/weronikawork6.jpg";
import weronikawork7 from "@content/projects/weronikawork7.png";
import weronikawork8 from "@content/projects/weronikawork8.jpg";

export const projectsImages = [
  { id: "agatawork1", thumbnail: agatawork1, preview: agatawork1 },
  { id: "agatawork2", thumbnail: agatawork2, preview: agatawork2 },
  { id: "agatawork3", thumbnail: agatawork3, preview: agatawork3 },
  { id: "agatawork4", thumbnail: agatawork4, preview: agatawork4 },
  { id: "agatawork5", thumbnail: agatawork5, preview: agatawork5 },
  { id: "agatawork6", thumbnail: agatawork6, preview: agatawork6 },
  { id: "agatawork7", thumbnail: agatawork7, preview: agatawork7 },
  { id: "agatawork8", thumbnail: agatawork8, preview: agatawork8 },
  { id: "alexwork1", thumbnail: alexwork1, preview: alexwork1 },
  { id: "alexwork2", thumbnail: alexwork2, preview: alexwork2 },
  { id: "alexwork3", thumbnail: alexwork3, preview: alexwork3 },
  { id: "alexwork4", thumbnail: alexwork4, preview: alexwork4 },
  { id: "alexwork5", thumbnail: alexwork5, preview: alexwork5 },
  { id: "alexwork6", thumbnail: alexwork6, preview: alexwork6 },
  { id: "alexwork7", thumbnail: alexwork7, preview: alexwork7 },
  { id: "alexwork8", thumbnail: alexwork8, preview: alexwork8 },
  { id: "barbarawork1", thumbnail: barbarawork1, preview: barbarawork1 },
  { id: "barbarawork2", thumbnail: barbarawork2, preview: barbarawork2 },
  { id: "barbarawork3", thumbnail: barbarawork3, preview: barbarawork3 },
  { id: "barbarawork4", thumbnail: barbarawork4, preview: barbarawork4 },
  { id: "barbarawork5", thumbnail: barbarawork5, preview: barbarawork5 },
  { id: "barbarawork6", thumbnail: barbarawork6, preview: barbarawork6 },
  { id: "barbarawork7", thumbnail: barbarawork7, preview: barbarawork7 },
  { id: "barbarawork8", thumbnail: barbarawork8, preview: barbarawork8 },
  { id: "christianwork1", thumbnail: christianwork1, preview: christianwork1 },
  { id: "christianwork2", thumbnail: christianwork2, preview: christianwork2 },
  { id: "christianwork3", thumbnail: christianwork3, preview: christianwork3 },
  { id: "christianwork4", thumbnail: christianwork4, preview: christianwork4 },
  { id: "christianwork5", thumbnail: christianwork5, preview: christianwork5 },
  { id: "christianwork6", thumbnail: christianwork6, preview: christianwork6 },
  { id: "christianwork7", thumbnail: christianwork7, preview: christianwork7 },
  { id: "christianwork8", thumbnail: christianwork8, preview: christianwork8 },
  { id: "dominikawork1", thumbnail: dominikawork1, preview: dominikawork1 },
  { id: "dominikawork2", thumbnail: dominikawork2, preview: dominikawork2 },
  { id: "dominikawork3", thumbnail: dominikawork3, preview: dominikawork3 },
  { id: "dominikawork4", thumbnail: dominikawork4, preview: dominikawork4 },
  { id: "dominikawork5", thumbnail: dominikawork5, preview: dominikawork5 },
  { id: "dominikawork6", thumbnail: dominikawork6, preview: dominikawork6 },
  { id: "dominikawork7", thumbnail: dominikawork7, preview: dominikawork7 },
  { id: "dominikawork8", thumbnail: dominikawork8, preview: dominikawork8 },
  { id: "gavilanwork1", thumbnail: gavilanwork1, preview: gavilanwork1 },
  { id: "gavilanwork2", thumbnail: gavilanwork2, preview: gavilanwork2 },
  { id: "gavilanwork3", thumbnail: gavilanwork3, preview: gavilanwork3 },
  { id: "gavilanwork4", thumbnail: gavilanwork4, preview: gavilanwork4 },
  { id: "gavilanwork5", thumbnail: gavilanwork5, preview: gavilanwork5 },
  { id: "gavilanwork6", thumbnail: gavilanwork6, preview: gavilanwork6 },
  { id: "gavilanwork7", thumbnail: gavilanwork7, preview: gavilanwork7 },
  { id: "gavilanwork8", thumbnail: gavilanwork8, preview: gavilanwork8 },
  { id: "lucaswork1", thumbnail: lucaswork1, preview: lucaswork1 },
  { id: "lucaswork2", thumbnail: lucaswork2, preview: lucaswork2 },
  { id: "lucaswork3", thumbnail: lucaswork3, preview: lucaswork3 },
  { id: "lucaswork4", thumbnail: lucaswork4, preview: lucaswork4 },
  { id: "lucaswork5", thumbnail: lucaswork5, preview: lucaswork5 },
  { id: "lucaswork6", thumbnail: lucaswork6, preview: lucaswork6 },
  { id: "lucaswork7", thumbnail: lucaswork7, preview: lucaswork7 },
  { id: "lucaswork8", thumbnail: lucaswork8, preview: lucaswork8 },
  { id: "matiaswork1", thumbnail: matiaswork1, preview: matiaswork1 },
  { id: "matiaswork2", thumbnail: matiaswork2, preview: matiaswork2 },
  { id: "matiaswork3", thumbnail: matiaswork3, preview: matiaswork3 },
  { id: "matiaswork4", thumbnail: matiaswork4, preview: matiaswork4 },
  { id: "matiaswork5", thumbnail: matiaswork5, preview: matiaswork5 },
  { id: "matiaswork6", thumbnail: matiaswork6, preview: matiaswork6 },
  { id: "matiaswork7", thumbnail: matiaswork7, preview: matiaswork7 },
  { id: "matiaswork8", thumbnail: matiaswork8, preview: matiaswork8 },
  { id: "monikawork1", thumbnail: monikawork1, preview: monikawork1 },
  { id: "monikawork2", thumbnail: monikawork2, preview: monikawork2 },
  { id: "monikawork3", thumbnail: monikawork3, preview: monikawork3 },
  { id: "monikawork4", thumbnail: monikawork4, preview: monikawork4 },
  { id: "monikawork5", thumbnail: monikawork5, preview: monikawork5 },
  { id: "monikawork6", thumbnail: monikawork6, preview: monikawork6 },
  { id: "monikawork7", thumbnail: monikawork7, preview: monikawork7 },
  { id: "monikawork8", thumbnail: monikawork8, preview: monikawork8 },
  { id: "patriciawork1", thumbnail: patriciawork1, preview: patriciawork1 },
  { id: "patriciawork2", thumbnail: patriciawork2, preview: patriciawork2 },
  { id: "patriciawork3", thumbnail: patriciawork3, preview: patriciawork3 },
  { id: "patriciawork4", thumbnail: patriciawork4, preview: patriciawork4 },
  { id: "patriciawork5", thumbnail: patriciawork5, preview: patriciawork5 },
  { id: "patriciawork6", thumbnail: patriciawork6, preview: patriciawork6 },
  { id: "patriciawork7", thumbnail: patriciawork7, preview: patriciawork7 },
  { id: "patriciawork8", thumbnail: patriciawork8, preview: patriciawork8 },
  { id: "sylwiawork1", thumbnail: sylwiawork1, preview: sylwiawork1 },
  { id: "sylwiawork2", thumbnail: sylwiawork2, preview: sylwiawork2 },
  { id: "sylwiawork3", thumbnail: sylwiawork3, preview: sylwiawork3 },
  { id: "sylwiawork4", thumbnail: sylwiawork4, preview: sylwiawork4 },
  { id: "sylwiawork5", thumbnail: sylwiawork5, preview: sylwiawork5 },
  { id: "sylwiawork6", thumbnail: sylwiawork6, preview: sylwiawork6 },
  { id: "sylwiawork7", thumbnail: sylwiawork7, preview: sylwiawork7 },
  { id: "tatianawork1", thumbnail: tatianawork1, preview: tatianawork1 },
  { id: "tatianawork2", thumbnail: tatianawork2, preview: tatianawork2 },
  { id: "tatianawork3", thumbnail: tatianawork3, preview: tatianawork3 },
  { id: "tatianawork4", thumbnail: tatianawork4, preview: tatianawork4 },
  { id: "tatianawork5", thumbnail: tatianawork5, preview: tatianawork5 },
  { id: "tatianawork6", thumbnail: tatianawork6, preview: tatianawork6 },
  { id: "tatianawork7", thumbnail: tatianawork7, preview: tatianawork7 },
  { id: "tatianawork8", thumbnail: tatianawork8, preview: tatianawork8 },
  { id: "weronikawork1", thumbnail: weronikawork1, preview: weronikawork1 },
  { id: "weronikawork2", thumbnail: weronikawork2, preview: weronikawork2 },
  { id: "weronikawork3", thumbnail: weronikawork3, preview: weronikawork3 },
  { id: "weronikawork4", thumbnail: weronikawork4, preview: weronikawork4 },
  { id: "weronikawork5", thumbnail: weronikawork5, preview: weronikawork5 },
  { id: "weronikawork6", thumbnail: weronikawork6, preview: weronikawork6 },
  { id: "weronikawork7", thumbnail: weronikawork7, preview: weronikawork7 },
  { id: "weronikawork8", thumbnail: weronikawork8, preview: weronikawork8 },
];
