import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  right: -200px;
  bottom: 100%;
  width: 300px;
  hieght: 100px;

  ${({ theme }) => theme.media.tablet} {
    font-size: 42px;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 52px;
  }

  ${({ theme }) => theme.media.tv} {
    font-size: 64px;
  }
`;

export const LauncherStand = styled.div`
  position: absolute;
  bottom: -30px;
  left: 40%;
  transform: rotate(-25deg);

  svg {
    width: 80px;
  }
`;

export const LauncherHand = styled.div`
  position: absolute;
  bottom: 10px;
  left: 45%;
  transform: rotate(25deg);
  transform-origin: 20px center;
  ${({ active }) => active && `animation: moveLauncherStand 2s;`}
  animation-timing-function: cubic-bezier(0.17, 1.01, 0.87, 1.12);
  animation-fill-mode: forwards;

  svg {
    width: 130px;
  }

  @keyframes moveLauncherStand {
    0% {
      transform: rotate(25deg);
    }
    100% {
      transform: rotate(-50deg);
    }
  }
`;

export const LauncherBall = styled.div`
  position: absolute;
  bottom: 25px;
  left: 80%;

  ${({ active }) =>
    active && `animation: y-axis 3.5s 0.1s forwards ease-in-out;`}

  svg {
    width: 20px;
    ${({ active }) =>
      active && `animation: x-axis 3.5s 0.1s forwards ease-in-out;`}
  }

  @keyframes x-axis {
    100% {
      transform: translateX(-1000px);
    }
  }
  @keyframes y-axis {
    50% {
      transform: translateY(-300px);
    }
    100% {
      transform: translateY(-70px);
    }
  }
`;
