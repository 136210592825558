import React, { useLayoutEffect, useState } from "react";
import ReactVivus from "react-vivus";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";

import AnimatedText from "@atoms/AnimatedText";
import Button from "@atoms/Button";
import PhrasesHome from "@atoms/PhrasesHome";
import ScrollArrows from "@atoms/ScrollArrows";

import { home } from "@content/home";

import {
  Wrapper,
  Columns,
  CTA,
  Text,
  Mail,
  Logo,
  Powered,
  HomeShape,
  HomeShapeTwo,
  HomeShapeThree,
  LineOne,
  LineTwo,
  LineThree,
} from "./styles.js";

import Gravity from "@images/gravity.svg";
import HomeShapeSVG from "@images/home-shape.svg";
import LineSVG from "@images/line.svg";

const Home = ({ visible, children }) => {
  const [activeActive, setActiveActive] = useState(false);
  const data = home;

  useLayoutEffect(() => {
    visible && setTimeout(() => setActiveActive(true), 1000);
  });

  return (
    <Wrapper id="home" visible={visible}>
      <h1>{children}</h1>
      <Columns>
        <CTA>
          <div>
            <h2>
              <ReactMarkdown children={data.header} />
            </h2>
            <Button color="black" pulse onClick={() => window.FB.CustomerChat.showDialog()}>
              <AnimatedText text={data.button} hoverAnimation />
            </Button>
          </div>
        </CTA>
        <Text>
          <ReactMarkdown children={data.subheader} />
        </Text>
        <Mail>
          <a href={`mailto:${data.email}`}>{data.email}</a>
          <PhrasesHome phrases={data.phrases} />
        </Mail>
        <Logo>
          <a
            href="http://gravitywarsaw.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Powered src={Gravity} />
          </a>
          <ReactMarkdown children={data.footer} />
        </Logo>
      </Columns>
      <ScrollArrows />
      {activeActive && (
        <>
          <LineOne visible={visible}>
            <ReactVivus
              id={`home-line-one`}
              option={{
                file: LineSVG,
                start: "inViewport",
                duration: 100,
                animTimingFunction: "EASE",
                type: "sync",
              }}
            />
          </LineOne>
          <LineTwo visible={visible}>
            <ReactVivus
              id={`home-line-two`}
              option={{
                file: LineSVG,
                start: "inViewport",
                duration: 100,
                animTimingFunction: "EASE",
                type: "sync",
              }}
            />
          </LineTwo>
          <LineThree visible={visible}>
            <ReactVivus
              id={`home-line-three`}
              option={{
                file: LineSVG,
                start: "inViewport",
                duration: 100,
                animTimingFunction: "EASE",
                type: "sync",
              }}
            />
          </LineThree>
          <HomeShape src={HomeShapeSVG} />
          <HomeShapeTwo src={HomeShapeSVG} />
          <HomeShapeThree src={HomeShapeSVG} />
        </>
      )}
    </Wrapper>
  );
};

Home.propTypes = {
  visible: PropTypes.bool,
};

Home.defaultProps = {
  visible: true,
};

export default Home;
