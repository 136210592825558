import React, { useState } from "react";
import ReactVivus from "react-vivus";

import { Wrapper, LauncherStand, LauncherHand, LauncherBall } from "./styles";

import LauncherSVG from "@images/launcher.svg";
import LauncherStandSVG from "@images/launcher-stand.svg";
import LauncherBallSVG from "@images/launcher-ball.svg";

const Launcher = () => {
  const [active, setActive] = useState(false);

  return (
    <Wrapper>
      <LauncherStand>
        <ReactVivus
          id={`launcher-stand`}
          option={{
            file: LauncherStandSVG,
            start: "inViewport",
            duration: 100,
            animTimingFunction: "EASE",
            type: "sync",
          }}
          callback={() => setActive(true)}
        />
      </LauncherStand>
      <LauncherHand active={active}>
        <ReactVivus
          id={`launcher-hand`}
          option={{
            file: LauncherSVG,
            start: "inViewport",
            duration: 100,
            animTimingFunction: "EASE",
            type: "sync",
          }}
          callback={() => setActive(true)}
        />
      </LauncherHand>
      <LauncherBall active={active}>
        <ReactVivus
          id={`clauncher-ball`}
          option={{
            file: LauncherBallSVG,
            start: "inViewport",
            duration: 100,
            animTimingFunction: "EASE",
            type: "sync",
          }}
          callback={() => setActive(true)}
        />
      </LauncherBall>
    </Wrapper>
  );
};

export default Launcher;
