import lucas from "@content/people/lucas.png";
import alexander from "@content/people/alexander.png";
import monika from "@content/people/monika.png";
import weronika from "@content/people/weronika.png";
import matias from "@content/people/matias.png";
import barbara from "@content/people/barbara.png";
import sylwia from "@content/people/sylwia.png";
import dominika from "@content/people/dominika.png";
import patricia from "@content/people/patricia.png";
import gavilian from "@content/people/gavilian.png";
import tatiana from "@content/people/tatiana.png";
import christian from "@content/people/christian.png";
import agata from "@content/people/agata.png";

export const peopleImages = [
  { id: "person1", image: lucas },
  { id: "person4", image: alexander },
  { id: "person5", image: monika },
  { id: "person6", image: weronika },
  { id: "person7", image: matias },
  { id: "person8", image: barbara },
  { id: "person9", image: sylwia },
  { id: "person10", image: dominika },
  { id: "person11", image: patricia },
  { id: "person12", image: gavilian },
  { id: "person13", image: tatiana },
  { id: "person14", image: christian },
  { id: "person15", image: agata },
];
