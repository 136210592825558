import styled, { css } from "styled-components";
import SVG from "react-inlinesvg";

export const Wrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.seashell};
  transition: opacity 0.6s 0.5s ease;
  ${({ active }) =>
    active
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 1;
    transition: opacity 0.3s 0.2s ease-in;
    pointer-events: none;
    ${({ active }) => active && `opacity: 0;`}
  }

  @keyframes introStart {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const TopContent = styled.section`
  position: relative;
  display: block;
  width: 160px;
  height: 160px;

  > h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    margin: 5px 0 0;
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    transform: translate3d(-50%, -50%, 0);
    opacity: 0.05;
  }

  ${({ theme }) => theme.media.tablet} {
    width: 260px;
    height: 260px;

    > h2 {
      margin: 10px 0 0;
      font-size: 115px;
    }
  }

  ${({ theme }) => theme.media.desktop} {
    width: 290px;
    height: 290px;

    > h2 {
      font-size: 155px;
    }
  }

  ${({ theme }) => theme.media.bigDesktop} {
    > h2 {
      font-size: 185px;
    }
  }

  ${({ theme }) => theme.media.tv} {
    width: 320px;
    height: 320px;

    > h2 {
      font-size: 195px;
    }
  }
`;

export const ShapeWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -50px;

  > p {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "giveny", serif;
    font-size: 56px;
  }

  ${({ theme }) => theme.media.tablet} {
    width: 200px;
    height: 200px;
    margin: -100px 0 0 -100px;

    > p {
      font-size: 66px;
    }
  }

  ${({ theme }) => theme.media.desktop} {
    width: 220px;
    height: 220px;
    margin: -110px 0 0 -110px;

    > p {
      font-size: 86px;
    }
  }

  ${({ theme }) => theme.media.tv} {
    width: 250px;
    height: 250px;
    margin: -125px 0 0 -125px;

    > p {
      font-size: 106px;
    }
  }
`;

export const HomeShape = styled(SVG)`
  display: flex;
  position: relative;
  width: 100px;
  height: 100px;
  pointer-events: none;

  ${({ theme }) => theme.media.tablet} {
    width: 200px;
    height: 200px;
  }

  ${({ theme }) => theme.media.desktop} {
    width: 220px;
    height: 220px;
  }

  ${({ theme }) => theme.media.tv} {
    width: 250px;
    height: 250px;
  }
`;

export const FooterText = styled.div`
  margin: 0;
  color: ${({ theme }) => theme.colors.grey};
  font-size: 14px;
  font-family: "gotham", sans-serif;
  line-height: 1.61;
  text-align: center;

  ${({ theme }) => theme.media.tablet} {
    font-size: 16px;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 18px;
  }
`;

export const Link = styled.a`
  transition: color 0.3s ease;

  :hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;
