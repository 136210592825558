import React, { useState, useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import ReactVivus from "react-vivus";
import _get from "lodash/get";

import {
  Wrapper,
  PeopleMobileWrapper,
  PopupPosition,
  Buttons,
  Line,
  Heart,
  LineCurved,
} from "./styles.js";

import Launcher from "@atoms/Launcher";
import BioButton from "@molecules/BioButton";
import PersonMobile from "@molecules/PersonMobile";
import Popup from "@molecules/Popup";

import LineSVG from "@images/line.svg";
import HeartSVG from "@images/heart.svg";
import LineCurvedSVG from "@images/line-curved.svg";

const Artists = ({
  visible,
  texts,
  people,
  onButtonClick,
  onClosePopupClick,
}) => {
  const [clickedButton, setClickedButton] = useState(1);
  const [buttonsHeight, setButtonsHeight] = useState(null);
  const [popupHeight, setPopupHeight] = useState(null);
  const stateRef = useRef();
  const buttonsRef = useRef(null);
  const popupRef = useRef(null);

  const checkButtonsHeight = () => {
    const height = _get(buttonsRef, "current.scrollHeight", 0);
    setButtonsHeight(height);
  };

  const checkPopupHeight = () => {
    const height = _get(popupRef, "current.scrollHeight", 0);
    setPopupHeight(height);
  };

  useLayoutEffect(() => {
    setTimeout(() => checkPopupHeight(), 400);
  });

  useLayoutEffect(() => {
    checkButtonsHeight();
  }, []);

  // make stateRef always have the current count
  // your "fixed" callbacks can refer to this object whenever
  // they need the current value.  Note: the callbacks will not
  // be reactive - they will not re-run the instant state changes,
  // but they *will* see the current value whenever they do run
  stateRef.current = clickedButton;

  return (
    <Wrapper id="artists">
      <Launcher />
      <header>
        <h2>
          <span>{texts.header.substring(0, 3)}</span>
          <span>{texts.header.substring(3, 7)}</span>
          <span>{texts.header.substring(7, 11)}</span>
        </h2>
        <h3>{texts.subheader}</h3>
        <h4>{texts.text1}</h4>
        <ReactMarkdown>{texts.text2}</ReactMarkdown>
      </header>
      <PeopleMobileWrapper>
        {people.map((person) => (
          <PersonMobile key={person.id} person={person} />
        ))}
      </PeopleMobileWrapper>
      <PopupPosition>
        <Popup
          ref={popupRef}
          visible={!visible}
          buttonIndex={stateRef.current}
          onClose={() => onClosePopupClick()}
        />
      </PopupPosition>
      <Buttons
        ref={buttonsRef}
        visible={visible}
        buttonsHeight={buttonsHeight}
        popupHeight={popupHeight}
      >
        {people.map((person, index) => (
          <BioButton
            key={index + "id"}
            location={`(${person.country})`}
            name={person.name}
            onClick={() => {
              onButtonClick();
              setClickedButton(person.id);
              checkButtonsHeight();
            }}
          />
        ))}
      </Buttons>
      <Line>
        <ReactVivus
          id={`artists-line`}
          option={{
            file: LineSVG,
            start: "inViewport",
            duration: 100,
            animTimingFunction: "EASE",
            type: "sync",
          }}
        />
      </Line>
      <Heart>
        <ReactVivus
          id={`artists-heart`}
          option={{
            file: HeartSVG,
            start: "inViewport",
            duration: 80,
            animTimingFunction: "EASE",
            type: "sync",
          }}
        />
      </Heart>
      <LineCurved>
        <ReactVivus
          id={`artists-line-curved`}
          option={{
            file: LineCurvedSVG,
            start: "inViewport",
            duration: 100,
            animTimingFunction: "EASE",
            type: "sync",
          }}
        />
      </LineCurved>
    </Wrapper>
  );
};

Artists.propTypes = {
  visible: PropTypes.bool,
  texts: PropTypes.object,
  people: PropTypes.array,
  onButtonClick: PropTypes.func,
};

Artists.defaultProps = {
  visible: true,
  texts: {},
  people: [],
};

export default Artists;
