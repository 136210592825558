export const people = [
  {
    id: "person1",
    name: "Lucas S.",
    mail: "hello@illustrators.pl",
    behance: "",
    city: "Maringa",
    country: "Brasil",
    bio:
      "My name is Lucas Storalic, I am an illustrator with over 8 years of experience working with advertising, editorial and institutional illustration. A few years ago I gathered my experiences that I acquired in advertising agencies and became a freelancer.",
    clients: "MasterCard, Booking.com",
    projects: [
      {
        id: "lucaswork1",
        name: "lucaswork1",
      },
      {
        id: "lucaswork2",
        name: "lucaswork2",
      },
      {
        id: "lucaswork3",
        name: "lucaswork3",
      },
      {
        id: "lucaswork4",
        name: "lucaswork4",
      },
      {
        id: "lucaswork5",
        name: "lucaswork5",
      },
      {
        id: "lucaswork6",
        name: "lucaswork6",
      },
      {
        id: "lucaswork7",
        name: "lucaswork7",
      },
      {
        id: "lucaswork8",
        name: "lucaswork8",
      },
    ],
  },
  {
    id: "person4",
    name: "Alexander B.",
    mail: "hello@illustrators.pl",
    behance: "",
    city: "Saint Petersburg",
    country: "Russia",
    bio:
      "I come from a small Siberian town, but I have been living and working in St. Petersburg for the last 15 years.I have been doing illustration for 10 years. During this time, I have worked for many international clients.In my work I use mostly vector graphics. I appreciate it for its clean lines and pure saturated colors.I always strive to ensure that my illustrations breathed and was alive.",
    clients: "",
    projects: [
      {
        id: "alexwork1",
        name: "alexwork1",
      },
      {
        id: "alexwork2",
        name: "alexwork2",
      },
      {
        id: "alexwork3",
        name: "alexwork3",
      },
      {
        id: "alexwork4",
        name: "alexwork4",
      },
      {
        id: "alexwork5",
        name: "alexwork5",
      },
      {
        id: "alexwork6",
        name: "alexwork6",
      },
      {
        id: "alexwork7",
        name: "alexwork7",
      },
      {
        id: "alexwork8",
        name: "alexwork8",
      },
    ],
  },
  {
    id: "person5",
    name: "Monika J.",
    mail: "hello@illustrators.pl",
    behance: "",
    city: "Edinburgh",
    country: "Scotland",
    bio:
      "Originally from Poland, my yearning for adventure and the unknown took me to live and work in Spain, Japan, SE Asia (mostly Vietnam), France, the US and now Scotland.I love working with bold colors, playful lines and strong shapes to create characters from sometimes more sometimes less imaginary worlds. Yes, I draw mostly women.",
    clients:
      "Adobe, Like the Wind Magazine, PBS, The Bravery Magazine, The Guardian, The Telegraph, Vans.",
    projects: [
      {
        id: "monikawork1",
        name: "monikawork1",
      },
      {
        id: "monikawork2",
        name: "monikawork2",
      },
      {
        id: "monikawork3",
        name: "monikawork3",
      },
      {
        id: "monikawork4",
        name: "monikawork4",
      },
      {
        id: "monikawork5",
        name: "monikawork5",
      },
      {
        id: "monikawork6",
        name: "monikawork6",
      },
      {
        id: "monikawork7",
        name: "monikawork7",
      },
      {
        id: "monikawork8",
        name: "monikawork8",
      },
    ],
  },
  {
    id: "person6",
    name: "Weronika B.",
    mail: "hello@illustrators.pl",
    behance: "",
    city: "Katowice",
    country: "Poland",
    bio:
      "Illustrator and visual narrator obsessed with light, color and floral patterns.I think with pictures and strive to create magical, fairy-tale worlds and rich characters.I believe that illustration, if honest, can be a fantastic way to build a dialogue.In my works you’ll find cute and original characters surrounded by their enchanted worlds.I like to play with styles and techniques, and although digital is closest to my heart, I am no stranger to traditional media.Beside being professional and reliable I’m also very friendly and it’s really easy to get alongwith me :) I feel best illustrating beautiful stories for small children as well as adult ones :).",
    clients: "",
    projects: [
      {
        id: "weronikawork1",
        name: "weronikawork1",
      },
      {
        id: "weronikawork2",
        name: "weronikawork2",
      },
      {
        id: "weronikawork3",
        name: "weronikawork3",
      },
      {
        id: "weronikawork4",
        name: "weronikawork4",
      },
      {
        id: "weronikawork5",
        name: "weronikawork5",
      },
      {
        id: "weronikawork6",
        name: "weronikawork6",
      },
      {
        id: "weronikawork7",
        name: "weronikawork7",
      },
      {
        id: "weronikawork8",
        name: "weronikawork8",
      },
    ],
  },
  {
    id: "person7",
    name: "Matias C.",
    mail: "hello@illustrators.pl",
    behance: "",
    city: "Buenos Aires",
    country: "Argentina",
    bio:
      "Is an Illustrator, from Buenos Aires, Argentina. He studied Visual Arts with a specialization in painting.His work is influenced by comics, fantastic art, and music. Illustration has been his language to tell stories and create characters; while sculpting has added creativity and imagination to his works. Crayons have been his greatest joy and through them, he managed to make a living out of his trade by drawing for graphic design studios and advertising agencies.",
    clients: "",
    projects: [
      {
        id: "matiaswork1",
        name: "matiaswork1",
      },
      {
        id: "matiaswork2",
        name: "matiaswork2",
      },
      {
        id: "matiaswork3",
        name: "matiaswork3",
      },
      {
        id: "matiaswork4",
        name: "matiaswork4",
      },
      {
        id: "matiaswork5",
        name: "matiaswork5",
      },
      {
        id: "matiaswork6",
        name: "matiaswork6",
      },
      {
        id: "matiaswork7",
        name: "matiaswork7",
      },
      {
        id: "matiaswork8",
        name: "matiaswork8",
      },
    ],
  },
  {
    id: "person8",
    name: "Barbara K.",
    mail: "hello@illustrators.pl",
    behance: "",
    city: "Warsaw",
    country: "Poland",
    bio:
      "My biggest art inspiration is nature and I try to express this fascination by creating rich botanical patterns, sometimes combined with various animal motifs. I started with just a fineliner and a piece of paper, but one day I discovered a pen tablet, which allowed me to expand my work range. It took me a couple of years plus graduating in Graphic Design to develop my signature style (which is constantly evolving) and now I am happy to share my work with the world.",
    clients: "",
    projects: [
      {
        id: "barbarawork1",
        name: "barbarawork1",
      },
      {
        id: "barbarawork2",
        name: "barbarawork2",
      },
      {
        id: "barbarawork3",
        name: "barbarawork3",
      },
      {
        id: "barbarawork4",
        name: "barbarawork4",
      },
      {
        id: "barbarawork5",
        name: "barbarawork5",
      },
      {
        id: "barbarawork6",
        name: "barbarawork6",
      },
      {
        id: "barbarawork7",
        name: "barbarawork7",
      },
      {
        id: "barbarawork8",
        name: "barbarawork8",
      },
    ],
  },
  {
    id: "person9",
    name: "Sylwia Z.",
    mail: "hello@illustrators.pl",
    behance: "",
    city: "Gliwice",
    country: "Poland",
    bio:
      "The most pleasant work for me is designing patterns and the opportunity to see the products made of them. They have been dealing with surface pattern design for several years and I approach each new project a bit like a child. I value constant cooperation and developing a common language with the ordering party. I like the process of looking for inspiration, preceding each order and involving the client in this process. Let's create something nice together!",
    clients: "",
    projects: [
      {
        id: "sylwiawork1",
        name: "sylwiawork1",
      },
      {
        id: "sylwiawork2",
        name: "sylwiawork2",
      },
      {
        id: "sylwiawork3",
        name: "sylwiawork3",
      },
      {
        id: "sylwiawork4",
        name: "sylwiawork4",
      },
      {
        id: "sylwiawork5",
        name: "sylwiawork5",
      },
      {
        id: "sylwiawork6",
        name: "sylwiawork6",
      },
      {
        id: "sylwiawork7",
        name: "sylwiawork7",
      },
    ],
  },
  {
    id: "person10",
    name: "Dominika S.",
    mail: "hello@illustrators.pl",
    behance: "",
    city: "Kielce",
    country: "Poland",
    bio:
      "She tried to draw even before she learned to sit and since then she has been constantly working on her skills.Currently, she combines passion with education - she is fascinated by designing and illustrating fantastic, non-existent universes and telling stories that take place in them.Privately? Synesthetic, animal-lover.",
    clients: "",
    projects: [
      {
        id: "dominikawork1",
        name: "dominikawork1",
      },
      {
        id: "dominikawork2",
        name: "dominikawork2",
      },
      {
        id: "dominikawork3",
        name: "dominikawork3",
      },
      {
        id: "dominikawork4",
        name: "dominikawork4",
      },
      {
        id: "dominikawork5",
        name: "dominikawork5",
      },
      {
        id: "dominikawork6",
        name: "dominikawork6",
      },
      {
        id: "dominikawork7",
        name: "dominikawork7",
      },
      {
        id: "dominikawork8",
        name: "dominikawork8",
      },
    ],
  },
  {
    id: "person11",
    name: "Patricia V d V.",
    mail: "hello@illustrators.pl",
    behance: "",
    city: "Lyon",
    country: "France",
    bio:
      "Since I was a child I painted portraits and cartoons and imagined fantasy stories based on my experiences in Peru, my native country.I became a digital illustrator and concept artist. My styles range from cartoon to realism.",
    clients:
      "I have worked with 2d and 3d animation production companies,children's book publishers and independent authors from different countries.",
    projects: [
      {
        id: "patriciawork1",
        name: "patriciawork1",
      },
      {
        id: "patriciawork2",
        name: "patriciawork2",
      },
      {
        id: "patriciawork3",
        name: "patriciawork3",
      },
      {
        id: "patriciawork4",
        name: "patriciawork4",
      },
      {
        id: "patriciawork5",
        name: "patriciawork5",
      },
      {
        id: "patriciawork6",
        name: "patriciawork6",
      },
      {
        id: "patriciawork7",
        name: "patriciawork7",
      },
      {
        id: "patriciawork8",
        name: "patriciawork8",
      },
    ],
  },
  {
    id: "person12",
    name: "Daniel M. G.",
    mail: "hello@illustrators.pl",
    behance: "",
    city: "Bogota",
    country: "Colombia",
    bio:
      "He has worked on projects around culture and music, organizing or participating in cultural movements with festivals, local and international brands, artists and initiatives that have led him to create a graphic universe through posters, covers, murals and publications, covering the art, fashion and culture.",
    clients: "",
    projects: [
      {
        id: "gavilanwork1",
        name: "gavilanwork1",
      },
      {
        id: "gavilanwork2",
        name: "gavilanwork2",
      },
      {
        id: "gavilanwork3",
        name: "gavilanwork3",
      },
      {
        id: "gavilanwork4",
        name: "gavilanwork4",
      },
      {
        id: "gavilanwork5",
        name: "gavilanwork5",
      },
      {
        id: "gavilanwork6",
        name: "gavilanwork6",
      },
      {
        id: "gavilanwork7",
        name: "gavilanwork7",
      },
      {
        id: "gavilanwork8",
        name: "gavilanwork8",
      },
    ],
  },
  {
    id: "person13",
    name: "Tatiana K.",
    mail: "hello@illustrators.pl",
    behance: "",
    city: "New York",
    country: "USA",
    bio:
      "I am an illustrator from USA. I really love adding character, emotion, and stories even to the smallest characters. In the past I have practiced classical oil painting. I’m very punctual, work quickly and make quality work. If you have a serious wish to create your own illustrated book, I will root for your story with the same enthusiasm as you do!",
    clients: "",
    projects: [
      {
        id: "tatianawork1",
        name: "tatianawork1",
      },
      {
        id: "tatianawork2",
        name: "tatianawork2",
      },
      {
        id: "tatianawork3",
        name: "tatianawork3",
      },
      {
        id: "tatianawork4",
        name: "tatianawork4",
      },
      {
        id: "tatianawork5",
        name: "tatianawork5",
      },
      {
        id: "tatianawork6",
        name: "tatianawork6",
      },
      {
        id: "tatianawork7",
        name: "tatianawork7",
      },
      {
        id: "tatianawork8",
        name: "tatianawork8",
      },
    ],
  },
  {
    id: "person14",
    name: "Christian G.",
    mail: "hello@illustrators.pl",
    behance: "",
    city: "Manila",
    country: "Philippines",
    bio:
      "Hi! I'm Chris originally from Philippines. A very passionate graphic designer / illustrator. I love my work and my family.",
    clients: "",
    projects: [
      {
        id: "christianwork1",
        name: "christianwork1",
      },
      {
        id: "christianwork2",
        name: "christianwork2",
      },
      {
        id: "christianwork3",
        name: "christianwork3",
      },
      {
        id: "christianwork4",
        name: "christianwork4",
      },
      {
        id: "christianwork5",
        name: "christianwork5",
      },
      {
        id: "christianwork6",
        name: "christianwork6",
      },
      {
        id: "christianwork7",
        name: "christianwork7",
      },
      {
        id: "christianwork8",
        name: "christianwork8",
      },
    ],
  },
  {
    id: "person15",
    name: "Agata L.",
    mail: "hello@illustrators.pl",
    behance: "",
    city: "Warsaw",
    country: "Poland",
    bio:
      "I graduated from the Academy of Fine Arts in Warsaw in the field of Graphics, but life threw me into the waves of advertising. I don't like to stand still, I like to experiment and look for ways of expression that are perfect for a specific topic, that's why I navigate between styles and I'm always looking for something new. Each task is like a separate challenge, I look at it from every angle and look for the way of expression that best reflects the spirit of this particular illustration.",
    clients: "",
    projects: [
      {
        id: "agatawork1",
        name: "agatawork1",
      },
      {
        id: "agatawork2",
        name: "agatawork2",
      },
      {
        id: "agatawork3",
        name: "agatawork3",
      },
      {
        id: "agatawork4",
        name: "agatawork4",
      },
      {
        id: "agatawork5",
        name: "agatawork5",
      },
      {
        id: "agatawork6",
        name: "agatawork6",
      },
      {
        id: "agatawork7",
        name: "agatawork7",
      },
      {
        id: "agatawork8",
        name: "agatawork8",
      },
    ],
  },
];
