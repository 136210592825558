import React from "react";

import Arrows from "@images/arrows.svg";

import { Wrapper } from "./styles";

const ScrollArrows = () => {
  return <Wrapper src={Arrows} />;
};

export default ScrollArrows;
