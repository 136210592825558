import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  min-height: 900px;

  ${({ visible }) => !visible && `pointer-events: none;`}
`;

export const Image = styled.div`
  position: absolute;
  top: -200px;
  right: -200px;
  background: black;
  width: 0;
  height: 1200px;
  overflow: hidden;
  transition: opacity 0.6s 0.4s ease, transform 5.9s 1s ease, width 0.4s 1s ease,
    height 0.4s 1s ease;
  opacity: 0;

  ${({ isClosing }) =>
    isClosing &&
    `width: 0; transition: opacity 0.6s 0.8s ease, transform 0.4s 1s ease, width 0.4s ease,
    height 0.4s 1s ease;`}

  ${({ visible }) =>
    visible &&
    `opacity: 1; transition: opacity 0.3s 0.1s ease, transform 0.6s 0.1s ease, width 0.6s 0.1s ease,
    height 0.6s 0.1s ease;`}

  ${({ visible }) => !visible && `width: 0;`}

    ${({ visible }) => visible && `width: 75%; height: 1200px;`}
`;

export const ImageInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1700px;
  height: 1200px;
  margin-top: -600px;
  margin-left: -850px;
  overflow: hidden;
  opacity: 0;
  transition: transform 1s 0.3s ease, opacity 0.2s 0.3s ease;
  // transform: translate3d(-50%, -50%, 0);
  trnsform-origin: center center;

  ${({ visible }) => visible && `opacity: 1;`}

  img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1700px;
    height: 1200px;
    transition: transform 1s 0.3s ease;
    transform: translate3d(-50%, -50%, 0) scale(0);
    trnsform-origin: center center;

    ${({ visible }) => visible && `transform: translate3d(-50%, -50%, 0);`}
  }

  ${({ theme }) => theme.media.tv} {
  }
`;

export const Text = styled.div`
  position: relative;
  width: 35%;
  min-height: 900px;
  padding-bottom: 100px;
  transition: transform 0.7s ease, opacity 0.7s ease;
  transition-delay: 0.2s;

  > span {
    margin: 0 0 20px;
    font-family: "gotham", sans-serif;
    font-size: 20px;
    text-transform: uppercase;
  }

  > h3 {
    margin: 0 0 38px;
    font-size: 60px;
    text-transform: uppercase;
  }

  > h4 {
    font-family: "gotham bold", sans-serif;
    font-size: 20px;
    text-transform: uppercase;
  }

  p {
    margin: 0 0 38px;
    font-family: "gotham", sans-serif;
    font-size: 20px;
    line-height: 2;
  }

  ${({ theme }) => theme.media.tv} {
    > span {
      margin: 0 0 24px;
      font-size: 24px;
    }

    > h3 {
      margin: 0 0 48px;
      font-size: 80px;
    }

    > h4 {
      font-size: 24px;
    }

    p {
      margin: 0 0 48px;
      font-size: 24px;
    }
  }

  ${({ visible }) =>
    visible
      ? css`
          transform: translate3d(0, 0, 0);
          opacity: 1;
          transition-delay: 1.4s;
        `
      : css`
          transform: translate3d(0, 150px, 0);
          opacity: 0;
          pointer-events: none;
        `}
`;

export const Line = styled.div`
  margin: 0 0 48px;
  background: ${({ theme }) => theme.colors.grey};
  height: 1px;
  transition: width 0.7s 1.4s ease;

  ${({ visible }) => (visible ? `width: 100%;` : `width: 0;`)};
`;

export const StyledButton = styled.div`
  position: absolute;
  top: 700px;
  right: 0;
  transition: transform 0.7s ease, opacity 0.7s ease;
  transition-delay: 0.2s;

  ${({ visible }) =>
    visible
      ? css`
          transform: translate3d(0, 0, 0);
          opacity: 1;
          transition-delay: 1.4s;
        `
      : css`
          transform: translate3d(0, 150px, 0);
          opacity: 0;
          pointer-events: none;
        `}
`;

export const Close = styled.div`
  cursor: pointer;
  transition: transform 0.7s ease, opacity 0.7s ease;
  transition-delay: 0.2s;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  background: black;
  color: white;
  font-family: "gotham", sans-serif;
  font-size: 54px;
  line-height: 1;

  ${({ visible }) =>
    visible
      ? css`
          transform: translate3d(0, 0, 0);
          opacity: 1;
          transition-delay: 1.4s;
        `
      : css`
          transform: translate3d(0, 150px, 0);
          opacity: 0;
          pointer-events: none;
        `}
`;

export const Projects = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  top: 0;
  left: 50%;
  width: 1560px;
  margin-left: -780px;
  ${({ visible }) => !visible && `pointer-events: none;`}
`;

export const Project = styled.a.attrs(({ href }) => ({
  href: href,
  target: "_blank",
  rel: "noopener noreferrer",
}))`
  position: relative;
  width: 390px;
  height: 390px;
  overflow: hidden;
  cursor: pointer;
  filter: grayscale(100%);
  transition: height 0.4s ease, filter 0.6s ease;

  &:before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: ${({ visible }) => (visible ? "390px" : "0px")};
    content: "";
    background: center center url(${({ image }) => image}) no-repeat;
    background-size: cover;
    transition: height 0.4s ease, filter 0.6s ease;
  }

  &::after {
    content: "X";
    cursor: pointer;
    transition: transform 0.7s ease, opacity 0.7s ease;
    transition-delay: 0.2s;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -45px 0 0 -45px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    background: black;
    color: white;
    font-family: "gotham", sans-serif;
    font-size: 54px;
    line-height: 1;
    transform: translate3d(0, 150px, 0);
    opacity: 0;
  }

  &:hover {
    filter: grayscale(0%);
  }

  &:nth-child(1)::before {
    transition: height 0.4s 0.7s ease;
  }

  &:nth-child(2)::before {
    transition: height 0.4s 0.6s ease;
  }

  &:nth-child(3)::before {
    transition: height 0.4s 0.5s ease;
  }

  &:nth-child(4)::before {
    transition: height 0.4s 0.4s ease;
  }

  &:nth-child(5)::before {
    transition: height 0.4s 0.3s ease;
  }

  &:nth-child(6)::before {
    transition: height 0.4s 0.2s ease;
  }

  &:nth-child(7)::before {
    transition: height 0.4s 0.1s ease;
  }

  &:nth-child(8)::before {
    transition: height 0.4s 0.1s ease;
  }

  &:nth-child(9)::before {
    transition: height 0.4s 0.1s ease;
  }

  &:nth-child(10)::before {
    transition: height 0.4s 0.1s ease;
  }

  &:nth-child(11)::before {
    transition: height 0.4s 0.1s ease;
  }

  &:nth-child(12)::before {
    transition: height 0.4s 0.1s ease;
  }

  ${({ visible }) =>
    visible &&
    css`
      &:nth-child(1)::before {
        transition: height 0.4s 1.1s ease;
      }

      &:nth-child(2)::before {
        transition: height 0.4s 1.2s ease;
      }

      &:nth-child(3)::before {
        transition: height 0.4s 1.3s ease;
      }

      &:nth-child(4)::before {
        transition: height 0.4s 1.4s ease;
      }

      &:nth-child(5)::before {
        transition: height 0.4s 1.5s ease;
      }

      &:nth-child(6)::before {
        transition: height 0.4s 1.6s ease;
      }

      &:nth-child(7)::before {
        transition: height 0.4s 1.7s ease;
      }

      &:nth-child(8)::before {
        transition: height 0.4s 1.8s ease;
      }

      &:nth-child(9)::before {
        transition: height 0.4s 1.9s ease;
      }

      &:nth-child(10)::before {
        transition: height 0.4s 2s ease;
      }

      &:nth-child(11)::before {
        transition: height 0.4s 2.1s ease;
      }

      &:nth-child(12)::before {
        transition: height 0.4s 2.2s ease;
      }
    `}

  ${({ active }) =>
    active &&
    css`
      &&:after {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    `}
`;

export const ProjectImage = styled.div`
  transition: opacity 0.6s ease !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: center center url(${({ image }) => image}) no-repeat;
  background-size: cover;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
`;
