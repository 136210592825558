import React, { useState } from "react";
import PropTypes from "prop-types";
import * as emailjs from "emailjs-com";

import Button from "@atoms/Button";
import Input from "@atoms/Input";
import TextHeader from "@atoms/TextHeader";

import { Wrapper, Response, Success, Error } from "./styles.js";

const ContactForm = ({ id, header, fields, response }) => {
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    message: "",
  });
  const [formSent, setFormSent] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [formSendSucces, setFormSendSucces] = useState(false);
  const [formSendError, setFormSendError] = useState(false);

  const handleUpdateField = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setFormSent(true);

    emailjs
      .sendForm(
        "AAM9xmLsVvsCjauKUAEGvKgk",
        "illustrators",
        "#contactForm",
        "user_eSllWp8flAh2sbHdCeaw8"
      )
      .then(
        (response) => {
          setShowResponse(true);
          setFormSendSucces(true);
        },
        (err) => {
          setShowResponse(true);
          setFormSendError(true);
        }
      );
  };

  return (
    <Wrapper id={id} active={!formSent}>
      <TextHeader text={header} />
      <form id="contactForm" onSubmit={(e) => handleSubmit(e)}>
        {fields.map((field) =>
          field.kind === "textarea" ? (
            <Input
              required
              textarea
              key={field.id}
              name={field.name}
              label={field.label}
              value={formData.user_name}
              onChangeEvent={(e) => handleUpdateField(e)}
            />
          ) : (
            <Input
              required
              key={field.id}
              name={field.name}
              label={field.label}
              value={formData.user_name}
              onChangeEvent={(e) => handleUpdateField(e)}
            />
          )
        )}
        <Button color="black" pulse>Send it</Button>
      </form>
      <Response active={showResponse}>
        {formSendSucces && (
          <Success>
            <h4>{response.success.header}</h4>
            <p>{response.success.text}</p>
          </Success>
        )}
        {formSendError && (
          <Error>
            <h4>{response.error.header}</h4>
            <p>{response.error.text}</p>
          </Error>
        )}
      </Response>
    </Wrapper>
  );
};

ContactForm.propTypes = {
  id: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object),
  response: PropTypes.object,
};

ContactForm.defaultProps = {
  id: "contact",
  header: "Contact Form",
  fields: [],
  response: {},
};

export default ContactForm;
