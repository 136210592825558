import styled from "styled-components";
import SVG from "react-inlinesvg";

export const Wrapper = styled(SVG)`
  display: none;
  position: absolute;
  bottom: 110px;
  left: 30%;
  margin-left: -90px;
  width: 140px;
  pointer-events: none;
  animation-name: moveArrows;
  animation-duration: 10s;
  animation-delay: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  @keyframes moveArrows {
    0% {
      transform: translate3d(0, 0, 0);
    }
    5% {
      transform: translate3d(0, 20px, 0);
    }
    10% {
      transform: translate3d(0, 0, 0);
    }
    15% {
      transform: translate3d(0, 20px, 0);
    }
    20% {
      transform: translate3d(0, 0, 0);
    }
    25% {
      transform: translate3d(0, 20px, 0);
    }
    30% {
      transform: translate3d(0, 0, 0);
    }
  }

  ${({ theme }) => theme.media.tablet} {
    display: block;
  }

  ${({ theme }) => theme.media.desktop} {
    bottom: 150px;
    left: 50%;
    margin-left: -90px;
    width: 180px;
  }

  ${({ theme }) => theme.media.tv} {
    margin-left: -90px;
    width: 180px;
  }
`;
