import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  border-bottom: 1px solid #ede4dd;
  padding: 30px 40px;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;

  ${({ theme }) => theme.media.tablet} {
    padding: 30px 50px;
  }

  > span {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: 0;
    height: 100%;
    background: black;
    transition: width 0.3s ease;
  }

  > div {
    position: relative;
    z-index: 1;
  }

  ${({ active }) =>
    active &&
    css`
      > span {
        width: 100%;
      }

      p {
        color: white !important;

        > span > span {
          // color: white !important;

          &::before,
          &::after {
            color: white !important;
          }
        }
      }

      > button {
        border-color: white;
      }
    `}
`;

export const SubLabel = styled.p`
  position: relative;
  display: block;
  font-family: "gotham", sans-serif;
  font-size: 14px;

  ${({ theme }) => theme.media.tablet} {
    font-size: 16px;
  }
`;

export const Label = styled.p`
  position: relative;
  display: block;
  font-size: 24px;
  white-space: nowrap;

  ${({ theme }) => theme.media.tablet} {
    font-size: 28px;
  }
`;
