import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;

  form {
    margin: 0;
    text-align: center;
    transition: opacity 0.6s ease;
    ${({ active }) => !active && `opacity: 0;`}
  }
`;

export const Response = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  font-size: 18px;
  text-align: center;
  background: white;
  opacity: 0;
  transition: all 1s 1s ease;

  ${({ active }) => (active ? `opacity: 1;` : `pointer-events: none;`)}

  h4 {
    margin: 0 0 16px;
    font-size: 24px;
    text-transform: uppercase;
  }

  p {
    font-size: 18px;
  }

  h4,
  p {
    position: relative;
    transform: translate3d(0, 100%, 0);
    opacity: 0;
    transition: all 2s 2s ease;

    ${({ active }) => active && `transform: translate3d(0, 0 ,0); opacity: 1;`}
  }

  ${({ theme }) => theme.media.tablet} {
    h4 {
      margin: 0 0 20px;
      font-size: 24px;
    }

    p {
      font-size: 18px;
    }
  }

  ${({ theme }) => theme.media.desktop} {
    h4 {
      margin: 0 0 24px;
      font-size: 30px;
    }

    p {
      font-size: 20px;
    }
  }

  ${({ theme }) => theme.media.tv} {
    h4 {
      margin: 0 0 32px;
      font-size: 50px;
    }

    p {
      font-size: 24px;
    }
  }
`;

export const Success = styled.div`
  h4 {
    color: ${({ theme }) => theme.colors.green};
  }
`;

export const Error = styled.div`
  h4 {
    color: ${({ theme }) => theme.colors.red};
  }
`;
