export const contact = {
  ourMission: {
    header: "Our Mission",
    text:
      "Why do we do what we do? It’s simple - because we work with illustration for many years and we know how important it is to find the right one.\n\n #### Why usually is it so difficult? \n\nIn most cases you have to know a particular artist, you depend upon a big studio that hires the best talents or you have to browse a hell of average portfolios to find a masterpiece on various portals. We’ve been there, we’ve done that - and we’ve chosen the ones worth your time. Check it yourself - meet our artists.",
    contactDetails: {
      header: "Contact details",
    },
  },
  contactForm: {
    header: "Contact form",
    fields: [
      {
        id: "user_name",
        name: "user_name",
        label: "Your name",
        type: "text",
        kind: "text",
      },
      {
        id: "user_email",
        name: "user_email",
        label: "Your email address",
        type: "email",
        kind: "text",
      },
      {
        id: "message",
        name: "message",
        label: "Type a message",
        type: "text",
        kind: "textarea",
      },
    ],
    response: {
      success: {
        header: "Thank you for your message",
        text: "We will get back to you soon.",
      },
      error: {
        header: "Sorry, something went wrong",
        text:
          "Please, check if you correctly filled all the fields or try again later.",
      },
    },
  },
};
