import React from "react";
import PropTypes from "prop-types";

import { Wrapper, Letter } from "./styles";

const AnimatedText = ({ text, active, hoverAnimation }) => {
  const splittedText = (text) => {
    return text.split("");
  };

  return (
    <Wrapper active={active} hoverAnimation={hoverAnimation}>
      {splittedText(text).map((letter, index) => (
        <Letter key={index + letter} index={index} data-letter={letter}>
          {letter}
        </Letter>
      ))}
    </Wrapper>
  );
};

AnimatedText.propTypes = {
  text: PropTypes.string,
  acive: PropTypes.bool,
  hoverAnimation: PropTypes.bool,
};

AnimatedText.defaultProps = {
  text: "",
  active: false,
  hoverAnimation: false,
};

export default AnimatedText;
