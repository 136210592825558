import styled from "styled-components";
import SVG from "react-inlinesvg";

export const Wrapper = styled.section`
  position: relative;
  margin: 0 0 100px;
  padding: 0 0 80px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 80%;
    bottom: -150px;
    width: 50vw;
    background: white;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 20px;
    background: black;
  }

  h1 {
    width: 100%;
    padding: 200px 0 120px;
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: 0.05;
  }

  ${({ theme }) => theme.media.tablet} {
    padding: 0 0 100px;

    &::before {
      right: 95%;
    }

    &::after {
      height: 30px;
    }

    h1 {
      font-size: 115px;
    }
  }

  ${({ theme }) => theme.media.desktop} {
    padding: 0 0 20px;

    &::after {
      height: 35px;
    }

    h1 {
      padding: 190px 0 80px;
      font-size: 155px;
    }
  }

  ${({ theme }) => theme.media.bigDesktop} {
    padding: 0 0 30px;

    h1 {
      padding: 240px 0 120px;
      font-size: 185px;
    }
  }

  ${({ theme }) => theme.media.tv} {
    padding: 0 0 30px;

    &::after {
      height: 40px;
    }

    h1 {
      padding: 300px 0 200px;
      font-size: 195px;
    }
  }
`;

export const Columns = styled.div`
  position: relative;

  ${({ theme }) => theme.media.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "CTA Text"
      "Mail Logo";
  }
`;

export const CTA = styled.div`
  padding: 0 0 80px;

  h2 {
    margin: 0 0 30px;

    > p {
      font-family: "giveny", serif;
      font-size: 30px;
      line-height: 1.2;
      text-transform: uppercase;
    }
  }

  button p {
    font-family: "giveny", serif;
    color: white;
  }

  button p,
  button span:before,
  button span:after {
    color: white;
  }

  ${({ theme }) => theme.media.tablet} {
    padding: 0 0 100px;

    h2 {
      > p {
        font-size: 50px;
      }
    }
  }

  ${({ theme }) => theme.media.desktop} {
    padding: 0 0 110px;

    h2 {
      > p {
        font-size: 60px;
      }
    }
  }

  ${({ theme }) => theme.media.bigDesktop} {
    padding: 0 0 130px;

    h2 {
      > p {
        font-size: 70px;
      }
    }
  }

  ${({ theme }) => theme.media.tv} {
    padding: 0 0 150px;
    h2 {
      > p {
        font-size: 80px;
      }
    }
  }
`;
export const Text = styled.div`
  padding: 8px 0 0;
  text-transform: uppercase;

  > * {
    display: none;
  }

  ${({ theme }) => theme.media.desktop} {
    > * {
      display: block;
    }

    p {
      font-size: 20px;
      line-height: 1.71;
    }
  }

  ${({ theme }) => theme.media.bigDesktop} {
    p {
      font-size: 22px;
    }
  }

  ${({ theme }) => theme.media.tv} {
    p {
      font-size: 24px;
    }
  }
`;
export const Mail = styled.div`
  > * {
    display: none;
  }

  ${({ theme }) => theme.media.desktop} {
    padding: 20px 0 0;

    > * {
      display: block;
    }

    a {
      font-family: "giveny", serif;
      font-size: 24px;
      line-height: 1.33;
      text-transform: uppercase;
    }
  }

  ${({ theme }) => theme.media.bigDesktop} {
    padding: 25px 0 0;
  }

  ${({ theme }) => theme.media.tv} {
    padding: 30px 0 0;
  }
`;
export const Logo = styled.div`
  text-align: right;
  font-family: "gotham", sans-serif;

  p {
    font-size: 16px;
    line-height: 1.33;
  }

  > a {
    display: inline-block;
  }

  ${({ theme }) => theme.media.tablet} {
    p {
      font-size: 20px;
    }
  }

  ${({ theme }) => theme.media.desktop} {
    p {
      font-size: 22px;
    }
  }

  ${({ theme }) => theme.media.tv} {
    p {
      font-size: 24px;
    }
  }
`;

export const Powered = styled(SVG)`
  width: 300px;
  margin: 0 0 30px;
  pointer-events: none;

  ${({ theme }) => theme.media.tv} {
    width: 400px;
    margin: 0 0 40px;
  }
`;

export const HomeShape = styled(SVG)`
  position: absolute;
  top: 120px;
  left: 50px;
  width: 100px;
  pointer-events: none;
  animation: moveToRight 30s ease;
  animation-delay: 1000ms;
  animation-fill-mode: forwards;
  opacity: 0;

  @keyframes moveToRight {
    0% {
      transform: translateX(0px);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    100% {
      transform: translateX(500px);
      opacity: 1;
    }
  }

  ${({ theme }) => theme.media.tablet} {
    top: 150px;
    left: 50px;
    width: 200px;
  }

  ${({ theme }) => theme.media.desktop} {
    top: 150px;
    left: 50px;
    width: 220px;
  }

  ${({ theme }) => theme.media.tv} {
    top: 180px;
    left: 50px;
    width: 250px;
  }
`;

export const HomeShapeTwo = styled(SVG)`
  position: absolute;
  top: 1200px;
  left: -120px;
  width: 100px;
  pointer-events: none;
`;

export const HomeShapeThree = styled(SVG)`
  position: absolute;
  top: 1450px;
  right: -70px;
  width: 50px;
  pointer-events: none;
`;

export const LineOne = styled.div`
  position: absolute;
  top: -200px;
  right: -100px;
  width: 1000px;
  transition: transform 1s ease;
  pointer-events: none;

  ${({ visible }) =>
    visible ? `transform: rotate(180deg);` : `transform: rotate(210deg);`}
`;

export const LineTwo = styled.div`
  position: absolute;
  top: 450px;
  right: -250px;
  width: 1000px;
  transition: transform 1s ease;
  pointer-events: none;

  ${({ visible }) =>
    visible ? `transform: rotate(40deg);` : `transform: rotate(70deg);`}
`;

export const LineThree = styled.div`
  position: absolute;
  top: 0px;
  left: -500px;
  width: 1500px;
  transition: transform 1s ease;
  pointer-events: none;

  ${({ visible }) =>
    visible ? `transform: rotate(85deg);` : `transform: rotate(105deg);`}
`;
