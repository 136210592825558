import React from "react";
import PropTypes from "prop-types";

import { Wrapper, StyledTextarea, StyledInput, Label } from "./styles.js";

const Input = ({
  name,
  label,
  type,
  value,
  textarea,
  required,
  onChangeEvent,
}) => {
  const handleChange = (e) => {
    onChangeEvent && onChangeEvent(e);
  };

  return (
    <Wrapper>
      {textarea ? (
        <StyledTextarea
          name={name}
          type={type}
          placeholder={label}
          required={required}
          onChange={(e) => handleChange(e)}
        />
      ) : (
        <StyledInput
          name={name}
          type={type}
          placeholder={label}
          required={required}
          onChange={(e) => handleChange(e)}
        />
      )}
      <Label htmlFor={name}>{label}</Label>
    </Wrapper>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  textarea: PropTypes.bool,
  required: PropTypes.bool,
  onChangeEvent: PropTypes.func,
};

Input.defaultProps = {
  name: "",
  label: "",
  type: "text",
  value: "",
  textarea: false,
  required: true,
  onChangeEvent: () => {},
};

export default Input;
