import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import ReactVivus from "react-vivus";

import TextHeader from "@atoms/TextHeader";

import ContactForm from "@molecules/ContactForm";

import { general } from "@content/general";
import { contact } from "@content/contact";

import { Wrapper, Mission, Details, CloudOne, CloudTwo } from "./styles.js";

import Cloud1SVG from "@images/cloud1.svg";
import Cloud2SVG from "@images/cloud2.svg";

const Contact = ({ visible }) => {
  const [cloudsActive, setCloudsActive] = useState(false);
  const missionData = contact.ourMission;
  const contactData = missionData.contactDetails;
  const address = general.address;
  const emails = general.emails;
  const phones = general.phones;
  const formData = contact.contactForm;

  return (
    <Wrapper visible={visible}>
      <Mission id="our-mission">
        <TextHeader text={missionData.header} />
        <ReactMarkdown children={missionData.text} />
        <Details>
          <h4>{contactData.header}</h4>
          <p>
            {emails.map((email, index) => (
              <a key={index + email} href={`mailto:${email}`}>
                {email}
              </a>
            ))}
          </p>
          <p>
            {phones.map((phone, index) => (
              <a key={index + phone} href={`tel:${phone.replace(/\s/g, "")}`}>
                {phone}
              </a>
            ))}
          </p>
          <p>{address}</p>
        </Details>
      </Mission>
      <ContactForm
        id="contact"
        header={formData.header}
        fields={formData.fields}
        response={formData.response}
      />
      <CloudOne active={cloudsActive}>
        <ReactVivus
          id={`contact-cloud-one`}
          option={{
            file: Cloud1SVG,
            start: "inViewport",
            duration: 100,
            animTimingFunction: "EASE",
            type: "sync",
          }}
        />
      </CloudOne>
      <CloudTwo active={cloudsActive}>
        <ReactVivus
          id={`contact-cloud-two`}
          option={{
            file: Cloud2SVG,
            start: "inViewport",
            duration: 100,
            animTimingFunction: "EASE",
            type: "sync",
          }}
          callback={() => setCloudsActive(true)}
        />
      </CloudTwo>
    </Wrapper>
  );
};

Contact.propTypes = {
  visible: PropTypes.bool.isRequired,
};

Contact.defaultProps = {
  visible: true,
};

export default Contact;
