import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./styles";

const PhrasesHome = ({ phrases }) => {
  return (
    <Wrapper>
      {phrases.map((phrase) => (
        <li key={phrase}>
          <span>{phrase}</span>
        </li>
      ))}
    </Wrapper>
  );
};

PhrasesHome.propTypes = {
  phrases: PropTypes.arrayOf(PropTypes.string),
};

PhrasesHome.defaultProps = {
  phrases: ["phrase1", "phrase2", "phrase3"],
};

export default PhrasesHome;
