import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  margin: 12px 0 48px;
  padding: 15px 0 0;
  font-family: "gotham", sans-serif;
  color: ${({ theme }) => theme.colors.black};
`;

export const StyledTextarea = styled.textarea.attrs(
  ({ name, type, value, required, onChange }) => ({
    id: name,
    name: name,
    type: type,
    value: value,
    onChange: onChange,
    required: required,
  })
)`
  position: relative;
  border-bottom: 3px solid ${({ theme }) => theme.colors.black};
  padding: 0 0 20px;
  width: 100%;
  min-height: 300px;
  font-size: 18px;
  outline: none;

  &::placeholder {
    color: transparent;
  }

  &:focus + label,
  &:not(:placeholder-shown) + label {
    transform: translate3d(0, -100%, 0) scale(0.7);
  }

  &:invalid:focus,
  &:invalid:visited {
    border-color: ${({ theme }) => theme.colors.red};
  }

  ${({ theme }) => theme.media.tablet} {
    font-size: 20px;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 22px;
  }

  ${({ theme }) => theme.media.tv} {
    font-size: 24px;
  }
`;

export const StyledInput = styled.input.attrs(
  ({ name, type, value, placeholder, required, onChange }) => ({
    id: name,
    name: name,
    type: type,
    value: value,
    placeholder: placeholder,
    onChange: onChange,
    required: required,
  })
)`
  position: relative;
  border-bottom: 3px solid ${({ theme }) => theme.colors.black};
  padding: 0 0 20px;
  width: 100%;
  font-size: 18px;
  outline: none;

  &::placeholder {
    color: transparent;
  }

  &:focus + label,
  &:not(:placeholder-shown) + label {
    transform: translate3d(0, -100%, 0) scale(0.7);
  }

  &:invalid:focus,
  &:invalid:visited {
    border-color: ${({ theme }) => theme.colors.red};
  }

  ${({ theme }) => theme.media.tablet} {
    font-size: 20px;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 22px;
  }

  ${({ theme }) => theme.media.tv} {
    font-size: 24px;
  }
`;

export const Label = styled.label.attrs(({ htmlFor }) => ({
  htmlFor: htmlFor,
}))`
  position: absolute;
  top: 15px;
  left: 0;
  display: block;
  font-size: 18px;
  color: black;
  transform: translate3d(0, 0, 0);
  transform-origin: top left;
  transition: all 0.2s ease;
  will-change: font-size, transform;

  ${({ theme }) => theme.media.tablet} {
    font-size: 20px;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 22px;
  }

  ${({ theme }) => theme.media.tv} {
    font-size: 24px;
  }
`;
