export const home = {
  title: "Illustratorspl",
  header: "Great Artists \n\nin One Place",
  subheader:
    "Meet our artists and discover the variety of their individual styles. As the picture is worth a thousand words - the illustrations speak for themselves!",
  button: "Let's chat",
  phrases: ["Artists", "Creativity", "Passion"],
  email: "hello@illustrators.pl",
  footer:
    "Illustrators.pl is a part of Gravity Warsaw  \n\nCreative hub connecting various artists \n\nfrom around the world",
};
