import styled from "styled-components/macro";

export const Wrapper = styled.div``;

export const Bar = styled.div`
  border-top: 1px solid #ede4dd;
  display: flex;
  width: 100%;

  > div {
    width: 100%;
  }
`;

export const Content = styled.div`
  overflow: hidden;
  width: 100%;
  height: ${({ contentHeight, isVisible }) =>
    isVisible ? contentHeight : 0}px;
  transition: all 0.2s ease-out;
`;

export const ContentPadding = styled.div`
  padding: 40px 0;
  text-align: center;

  > span {
    margin: 40px 0 20px;
    font-family: "gotham", sans-serif;
    font-size: 16px;
    text-transform: uppercase;
  }

  > h3 {
    margin: 0 0 38px;
    font-size: 40px;
    text-transform: uppercase;
  }

  > h4 {
    font-family: "gotham bold", sans-serif;
    font-size: 18px;
    text-transform: uppercase;
  }

  > p {
    margin: 0 0 38px;
    font-family: "gotham", sans-serif;
    font-size: 18px;
    line-height: 2;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 40px;
  width: 100%;

  > button:last-child {
    margin-left: 20px;
  }
`;

export const Projects = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  width: 100%;
  height: ${({ contentHeight, isVisible }) =>
    isVisible ? contentHeight + 50 : 0}px;
  transition: all 0.2s ease-out;

  ${({ theme }) => theme.media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Project = styled.div`
  width: 100%;
  height: 375px;
  overflow: hidden;
  background: center center url(${({ image }) => image}) no-repeat;
  background-size: cover;
`;
