import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import Button from "@atoms/Button";
import BioButtonMobile from "@molecules/BioButtonMobile";

import {
  Wrapper,
  Content,
  Bar,
  ContentPadding,
  Buttons,
  Projects,
  Project,
} from "./styles";

import { projectsImages } from "@content/projects/projectsImages";

const PersonMobile = ({ person }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);
  const [isProjectsOpen, setIsProjectsOpen] = useState(false);
  const [projectsHeight, setProjectsHeight] = useState(0);
  const projectsRef = useRef(null);

  useEffect(() => {
    const height = _get(projectsRef, "current.scrollHeight", 0);
    setProjectsHeight(height);
  }, [setProjectsHeight]);

  const toggleAccordion = () => {
    const height = _get(contentRef, "current.scrollHeight", 0);
    setIsOpen(!isOpen);
    setIsProjectsOpen(false);
    setContentHeight(height);
  };

  const toggleProjects = () => {
    const height = _get(projectsRef, "current.scrollHeight", 0);
    setIsProjectsOpen(!isProjectsOpen);
    setProjectsHeight(height);
    setTimeout(() => {
      const biggerHeight = _get(contentRef, "current.scrollHeight", 0);
      setContentHeight(biggerHeight);
    }, 100);
  };

  const getProjectThumbnail = (id) => {
    const chosenProject = projectsImages.filter((project) => {
      return project.id === id;
    });

    return chosenProject[0].thumbnail;
  };

  return (
    <Wrapper>
      <Bar>
        <BioButtonMobile
          active={isOpen}
          key={person.id}
          location={`(${person.country})`}
          name={person.name}
          onClick={() => {
            toggleAccordion();
          }}
        />
      </Bar>
      <Content
        isVisible={isOpen}
        contentHeight={contentHeight}
        ref={contentRef}
      >
        <ContentPadding>
          <span>
            ({person.city}, {person.country})
          </span>
          <h3>{person.name}</h3>
          <h4>Bio</h4>
          <p>{person.bio}</p>
          <h4>Clients</h4>
          <p>{person.clients}</p>
          <Buttons>
            <Button color="black" onClick={() => toggleProjects()}>
              My works
            </Button>
            <Button color="red">Book my time</Button>
          </Buttons>
        </ContentPadding>
      </Content>
      <Projects
        isVisible={isProjectsOpen}
        contentHeight={projectsHeight}
        ref={projectsRef}
      >
        {person.projects.map((project, index) => (
          <Project image={getProjectThumbnail(project.id)} key={project.id} />
        ))}
      </Projects>
    </Wrapper>
  );
};

PersonMobile.propTypes = {
  person: PropTypes.object,
};

PersonMobile.defaultProps = {
  person: { name: "unknown" },
};

export default PersonMobile;
