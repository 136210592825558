import styled, { css } from "styled-components";

export const PeopleMobileWrapper = styled.section`
  ${({ theme }) => theme.media.desktop} {
    display: none;
  }
`;

export const Wrapper = styled.section`
  position: relative;
  padding: 0 0 180px;
  transition: all 0.2s ease-out;

  > header {
    position: relative;
    max-width: 325px;
    margin: 100px auto 150px;
    padding: 100px 0 130px;
    background: black;
    color: white;
    text-align: center;

    h2 {
      position: relative;
      margin: 0 0 20px;
      font-size: 80px;
      text-transform: uppercase;

      > span:nth-child(1) {
        position: absolute;
        top: -30px;
        right: 50%;
        margin-right: -35px;
        font-size: 32px;
      }
    }

    h3 {
      position: relative;
      margin: 0 0 50px;
      font-family: "gotham bold", sans-serif;
      font-size: 20px;
      text-transform: uppercase;
    }

    h4 {
      position: relative;
      margin: 0 0 40px;
      font-size: 28px;
      text-transform: uppercase;
    }

    p {
      position: relative;
      font-family: "gotham", sans-serif;
      font-size: 18px;
      line-height: 1.71;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: -200px;
      right: -200px;
      background: black;
    }
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 50px;
    left: 50px;
    height: 20px;
    background: ${({ theme }) => theme.colors.black};
  }

  ${({ theme }) => theme.media.tablet} {
    > header {
      max-width: 1000px;
      margin: 100px 0 150px 25%;
      padding: 100px 0 130px 40px;
      text-align: left;

      h2 {
        margin: 0 0 20px;
        font-size: 80px;
        text-transform: uppercase;

        > span:nth-child(1) {
          position: absolute;
          top: -30px;
          right: 100%;
          margin: 0;
          padding-right: 100px;
          font-size: 32px;
          color: black;
        }

        > span:nth-child(2) {
          position: absolute;
          right: 100%;
          padding-right: 80px;
          color: black;
        }
      }

      h3 {
        margin: 0 0 50px;
        font-size: 20px;
      }

      h4 {
        margin: 0 0 30px;
        font-size: 32px;
      }

      p {
        font-size: 20px;
      }

      &::before {
        right: auto;
        left: 100%;
        width: 50vw;
      }
    }
  }

  ${({ theme }) => theme.media.desktop} {
    > header {
      margin: 100px 0 150px 40%;
      padding: 100px 60px 130px;
      h2 {
        margin: 0 0 30px;
        font-size: 200px;

        > span:nth-child(1) {
          top: -35px;
          font-size: 42px;
          padding-right: 280px;
        }

        > span:nth-child(2) {
          padding-right: 120px;
        }
      }

      h3 {
        margin: 0 0 50px;
        font-size: 22px;
      }

      h4 {
        margin: 0 0 30px;
        font-size: 36px;
      }

      p {
        font-size: 22px;
      }
    }
  }

  ${({ theme }) => theme.media.bigDesktop} {
    > header {
      margin: 100px 0 150px 50%;
    }
  }

  ${({ theme }) => theme.media.tv} {
    > header {
      padding: 100px 0 130px 80px;
      h2 {
        font-size: 220px;

        > span:nth-child(1) {
          top: -40px;
          font-size: 48px;
          padding-right: 340px;
        }

        > span:nth-child(2) {
          padding-right: 180px;
        }
      }

      h3 {
        margin: 0 0 50px;
        font-size: 24px;
      }

      h4 {
        margin: 0 0 30px;
        font-size: 42px;
      }

      p {
        font-size: 24px;
      }
    }
  }
`;

export const PopupPosition = styled.section`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 0;
  overflow: visible;
`;

export const Buttons = styled.div`
  display: none;
  transition: transform 0.7s 0.3s ease, opacity 0.7s 0.3s ease,
    height 0.7s 1s ease;
  // transition-delay: 1s;

  ${({ visible }) =>
    visible
      ? css`
          transform: translate3d(0, 0, 0);
          opacity: 1;
          transition: transform 0.7s 1s ease, opacity 0.7s 1s ease,
            height 0.7s 0.4s ease;
        `
      : css`
          transform: translate3d(0, -150px, 0);
          opacity: 0;
        `}

  ${({ theme }) => theme.media.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
    height: ${({ visible, buttonsHeight, popupHeight }) =>
      !visible && buttonsHeight ? popupHeight : buttonsHeight}px;
  }
`;

export const Line = styled.div`
  position: absolute;
  top: 80px;
  left: -400px;
  width: 880px;
  transform: rotate(35deg);
  transform-origin: center center;
`;

export const Heart = styled.div`
  position: absolute;
  top: 670px;
  left: 0px;
  width: 130px;

  ${({ theme }) => theme.media.tablet} {
    top: 480px;
    left: 0px;
  }
`;

export const LineCurved = styled.div`
  position: absolute;
  top: 670px;
  left: 200px;
  width: 180px;
`;
