import React, { useState } from "react";
import PropTypes from "prop-types";

import AnimatedText from "@atoms/AnimatedText";
import ButtonCircle from "@atoms/ButtonCircle";

import { Wrapper, SubLabel, Label } from "./styles.js";

const BioButton = ({ location, name, active, onClick }) => {
  const [buttonHover, setButtonHover] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleMouseOver = () => setButtonHover(true);

  const handleMouseLeave = () => setButtonHover(false);

  const handleClick = () => {
    onClick && onClick();
    setButtonClicked(true);

    setTimeout(() => setButtonClicked(false), 1300);
  };

  return (
    <Wrapper
      active={buttonHover || active}
      clicked={buttonClicked}
      onMouseOver={() => handleMouseOver()}
      onMouseLeave={() => handleMouseLeave()}
      onClick={() => handleClick()}
    >
      <span></span>
      <span></span>
      <span></span>
      <div>
        <SubLabel>
          <AnimatedText active={buttonHover} text={location} />
        </SubLabel>
        <Label>
          <AnimatedText active={buttonHover} text={name} />
        </Label>
      </div>
      <ButtonCircle active={buttonHover}>+</ButtonCircle>
    </Wrapper>
  );
};

BioButton.propTypes = {
  location: PropTypes.string,
  name: PropTypes.string,
};

BioButton.defaultProps = {
  location: "",
  name: "",
};

export default BioButton;
