import React from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";

import {
  Wrapper,
  TopContent,
  ShapeWrapper,
  HomeShape,
  FooterText,
  Link,
} from "./styles.js";

import HomeShapeSVG from "@images/home-shape-intro.svg";
import { general } from "@content/general";

const Intro = ({ active }) => {
  return (
    <Wrapper active={active}>
      <TopContent>
        <h2>Illustratorspl</h2>
        <ShapeWrapper>
          <HomeShape src={HomeShapeSVG} />
          <p>I.</p>
        </ShapeWrapper>
      </TopContent>
      <FooterText>
        <ReactMarkdown
          renderers={{
            link: (props) => (
              <Link href={props.href} target="_blank">
                {props.children}
              </Link>
            ),
          }}
          children={general.footer}
        />
      </FooterText>
    </Wrapper>
  );
};

Intro.propTypes = {
  active: PropTypes.bool,
};

Intro.defaultProps = {
  active: true,
};

export default Intro;
